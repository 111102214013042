import * as React from "react";
import {
  Stack,
  styled,
  Typography,
} from "@mui/material";
import {
  faqUrl,
  noticeUrl,
  partnerUrl,
  PrivacyPolicy,
  recruitmentUrl,
  serviceUrl,
  TermsOfUse,
  MarketingAgree
} from "../constants/urls";
import { useHistory } from "react-router";
import { useMediaQueryForMobile } from "../hooks/useMediaQueryForMobile";
import MediaQuery from "react-responsive";
export interface IFooterProps {}

export function Footer(props: IFooterProps) {
  const isMobile=useMediaQueryForMobile();
  const history = useHistory();
  const goRecruitment = React.useCallback(() => {
    history.push(recruitmentUrl);
    window.scrollTo({
      top: 0,
    });
  }, [history]);
  const goFaq = React.useCallback(() => {
    history.push(faqUrl);
    window.scrollTo({
      top: 0,
    });
  }, [history]);

  const goNotice = React.useCallback(() => {
    history.push(noticeUrl);
    window.scrollTo({
      top: 0,
    });
  }, [history]);

  const goTermsOfUse = React.useCallback(() => {
    history.push(TermsOfUse);
    window.scrollTo({
      top: 0,
    });
  }, [history]);

  const goService = React.useCallback(() => {
    history.push(serviceUrl);
    window.scrollTo({
      top: 0,
    });
  }, [history]);

  const goPartnerShip = React.useCallback(() => {
    history.push(partnerUrl);
    window.scrollTo({
      top: 0,
    });
  }, [history]);
  const goPrivacyPolicy = React.useCallback(() => {
    history.push(PrivacyPolicy);
    window.scrollTo({
      top: 0,
    });
  }, [history]);

  const goMarketingAgreement = React.useCallback(() => {
    history.push(MarketingAgree);
    window.scrollTo({
      top: 0,
    });
  }, [history]);



  return (
    <FooterMain>
      <FooterFrame>
        <Stack sx={{mt: '36px', width:'100%'}} direction={['column','row']}>
          <Stack direction={'row'} flexWrap={"wrap"} >
            <PBText  onClick={goService}>
              {"서비스 소개"}
            </PBText>
            {/* <PBText onClick={goRecruitment}>
              {"채용"}
            </PBText> */}
            <PBText  onClick={goNotice}>
              {"공지사항"}
            </PBText>
            <PBText onClick={goPartnerShip}>
              {"제휴 문의"}
            </PBText>
            <PBText onClick={goFaq}>
              {"FAQ"}
            </PBText>
            <div style={{ display: "flex"}}>
            <PBText sx={{color: '#A5A5A5', textAlign:[ 'left', 'center']}} onClick={goTermsOfUse}>
              {"이용 약관"}
            </PBText>
            <PBText sx={{width: '97px', color: '#A5A5A5', textAlign:['left', 'center']}} onClick={goPrivacyPolicy}>
              {"개인정보처리방침"}
            </PBText>
            <PBText sx={{width: '120px', color: '#A5A5A5', textAlign:['left', 'center']}} onClick={goMarketingAgreement}>
              {"마케팅 정보 활용 동의"}
            </PBText>
            </div>
            
          {/* </Stack>
          <Stack ml={['0', '30px']} mt={['6px', '0']} direction={'row'} spacing={'30px'}> */}
          </Stack>
        </Stack>
        <Stack sx={{mt: ['20px', '16px']}}
          direction={['column','row']}
          alignItems={['flex-start', 'center']}
          >
          <PNText>
            {"서클플랫폼 주식회사"}
          </PNText>
          <Divider/>
          <PNText>
            {"대표 최영우"}
          </PNText>
          <Divider/>
          <PNText>
            {"주소 :서울툭별시 강남구 테헤란로5길7 9층, 120호 (KG타워)"}
          </PNText>
        </Stack>
        <Stack sx={{mt: ['6px', '0']}}
          direction={['column-reverse','row']}
          alignItems={['flex-start', 'center']}
          >
          <PNText>
            {"사업자등록번호: 557-87-02223"}
          </PNText>
          <Divider/>
          <PNText>
            {"통신판매업신고 : 제2022-서울강남-01773호"}
          </PNText>
        </Stack>
        <Stack sx={{mt: ['6px', '0']}}
          direction={['column-reverse','row']}
          alignItems={['flex-start', 'center']}
          >
          <PNText>
            {"메일 : support@cplat.io"}
          </PNText>
          <Divider/>
          <PNText>
            {"전화 : 02-558-2166"}
          </PNText>
        </Stack>
        <PNText sx={{mt: ['auto', '8px'], mb : [ '30px', '0px' ], color: '#DADADA'}}>
            {"ⓒ circleplatform. All rights reserved."}
          </PNText>
      </FooterFrame>
    </FooterMain>
  );
}

const Divider = styled( 'div' )( ({ theme }: any) => ({
  height: '10px',
  width: '2px',
  background: '#E8E8E8',
  marginLeft: '12px',
  marginRight: '12px',
  [theme.breakpoints.down("sm")] : {
    display : 'none',
    width: '0',
    margin: '0'
  }
}));

const PNText = styled( Typography )(({ theme }: any) => ({
  fontFamily: 'Pretendard',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '12px',
  lineHeight: '30px',
  alignItems: 'center',
  color: '#A5A5A5',
  height: '30px',
  [theme.breakpoints.down("sm")] : {
    fontSize: '12px',
    lineHeight: '16px',
    height: '16px',
  } 
}));

const PBText = styled( Typography )(({ theme }: any) => ({
  marginRight: "60px",
  marginBottom: "5px",
  cursor: "pointer",
  fontFamily: 'Pretendard-title',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '14px',
  lineHeight: '30px',
  color: '#555555',
  // minWidth: '60px',
  // border: "1px solid green",
  height: '30px',
  width: 'auto',
  textAlign: "start",
  [theme.breakpoints.down("sm")] : {
    marginRight: "15px",
    fontSize: '14px',
    lineHeight: '30px',
    width: 'auto',
  }
}));

export const FooterWrapper = styled("div")(({ theme }: any) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  backgroundColor: "#FAFAFA",
}));
const FooterMain = styled("footer")(({ theme }: any) => ({
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  alignItems: "center",
}));
export const FooterFrame = styled("div")(({ theme }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  // maxWidth: 1440,
  // paddingLeft: 200,
  width: 1440,
  height: "200px",
  [theme.breakpoints.down("sm")] : {
    paddingLeft: 0,
    minWidth: '270px',
    width:'80%',
    height: '270px',
  }
  , [theme.breakpoints.down(1440)] : {
    width: "100%",
    paddingLeft: 20,
  }
}));
