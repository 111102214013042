import * as React from "react";

export const useLayoutSize = () => {
  const [size, setSize] = React.useState({
    width: document.body.clientWidth,
    height: document.body.clientHeight,
  });

  React.useLayoutEffect(() => {
    setSize({
      width: document.body.clientWidth,
      height: document.body.clientHeight,
    });
  }, []);

  return size;
};
