import { styled } from "@mui/material";
import Menu from "@mui/material/Menu";
export const MyMenu = styled(Menu)({
  "& .MuiPaper-root": {
    width: 119,
    height: 140,
    borderRadius: 0,
    overflowY: "hidden",
  },
});
