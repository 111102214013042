import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material";
export const MyMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: "white !important",
  [theme.breakpoints.up("sm")]: {
    "&:hover": {
      backgroundColor: "#ffe0b5 !important",
    },
  },
  width: 119,
  height: 46,
  justifyContent: "center",
}));
