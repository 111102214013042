import { ButtonProps, Button, styled } from "@mui/material";
import * as React from "react";

export interface IMyMenuButtonProps extends ButtonProps {
  clicked: boolean;
}

export const MyMenuButton: React.FC<IMyMenuButtonProps> = ({
  children,
  clicked,
  ...others
}) => {
  return (
    <StyledButton {...others} className={clicked ? "clicked" : ""}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  borderBottom: "2px solid transparent",
  width: 110,
  borderRadius: 0,
  transition: `border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  "&.clicked": {
    borderBottomColor: theme.palette.primary.main,
  },
}));
