import { IconButton, IconButtonProps, styled } from "@mui/material";
import * as React from "react";
import { ReactComponent as MenuIcon } from "../assets/icons/MenuIcon.svg";
import { ReactComponent as CloseIcon } from "../assets/icons/CloseIcon.svg";

export interface IMenuButtonProps extends IconButtonProps {
  isDarkMode?: boolean;
  clicked: boolean;
}

export function MenuButton({
  isDarkMode = false,
  clicked,
  style,
  ...others
}: IMenuButtonProps) {
  return (
    <StyledIconButton style={style} darkmode={isDarkMode} {...others}>
      {clicked ? <CloseIcon /> : <MenuIcon />}
    </StyledIconButton>
  );
}

const StyledIconButton = styled(IconButton)<{ darkmode: boolean }>(
  ({ darkmode }) => ({
    height: 58,
    width: 58,
    boxSizing: "border-box",
    "& path": {
      stroke: darkmode ? "white" : "",
    },
  })
);
