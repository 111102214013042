export const homeUrl = '/';
export const serviceUrl = '/services';
export const promotion_3o3 = '/3o3_promotion2310';
export const companyUrl = '/company';
export const appDownloadUrl = '/appDownload';
export const recruitmentUrl = '/recruitment';
export const clientsUrl = '/clients';

export const partnerUrl = '/clients/partner';
export const noticeUrl = '/clients/notice';
export const ContactUrl = '/clients/contact';
export const faqUrl = '/clients/faq';

export const naverRegisterToken = '/naverRegisterToken';
export const naverRegisterStart = '/naverRegisterStart';
export const signIn = '/signIn';
export const registerTerms = '/registerTerms';
export const termsDetail = '/termsDetail';
export const smartStoreGuideUrl = '/smartStoreGuide';
export const marketFinishUrl = '/market/finish';

export const logout = '/logout';
export const TermsOfUse = '/termsOfUse';
export const PrivacyPolicy = '/privacy';
export const MarketingAgree = '/marketingAgree';
