import * as React from "react";

type ScrollEventCallbackType = {
  onScrollUp: (offset?: number) => void;
  onScrollDown: (offset?: number) => void;
};
export const useScrollEvent = ({
  onScrollUp,
  onScrollDown,
}: ScrollEventCallbackType) => {
  const [y, setY] = React.useState(0);

  const handleScroll = React.useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > window.scrollY) {
        onScrollUp(y)
      } else if (y < window.scrollY) {
        onScrollDown(y)
      }
      setY(window.scrollY);
    },
    [y]
  );

  React.useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
};
